import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import "../styles/swiper-bundle.min.css"
import "../styles/global.css"
import Img from "gatsby-image"

const initialValues = {
    name: "",
    email: "",
    project: "",
    message: ""
}
export default function Home({ data }) {
    const [inputs, setInputs] = useState(initialValues)
    const { title, description, copyright, contact } = data.site.siteMetadata
    const perfilImg = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("perfil_enmanuel.png"))?.node.childImageSharp.fluid
    const aboutImg = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("about_enmanuel.jpg"))?.node.childImageSharp.fluid
    const portfolio1Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("portfolio1.jpg"))?.node.childImageSharp.fluid
    const portfolio2Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("portfolio2.png"))?.node.childImageSharp.fluid
    const portfolio3Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("portfolio3.png"))?.node.childImageSharp.fluid
    const portfolio4Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("portfolio4.png"))?.node.childImageSharp.fluid
    const portfolio5Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("portfolio5.png"))?.node.childImageSharp.fluid
    const portfolio6Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("portfolio6.png"))?.node.childImageSharp.fluid
    const projectImg = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("project_enmanuel.png"))?.node.childImageSharp.fluid
    const testimonial1Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("testimonial1.jpg"))?.node.childImageSharp.fluid
    const testimonial2Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("testimonial2.jpg"))?.node.childImageSharp.fluid
    const testimonial3Img = data.allFile.edges.find(e => e.node.childImageSharp.fluid.src.endsWith("testimonial3.jpg"))?.node.childImageSharp.fluid
    
    useEffect(() => {
        if(document.querySelector("script[src='/javascripts/swiper-bundle.min.js']") == null) {
            const script = document.createElement("script")
            script.src = "/javascripts/swiper-bundle.min.js"
            script.onload = () => {
                const script2 = document.createElement("script")
                script2.src = "/javascripts/main.js"
                document.body.append(script2);
            }
        
            document.body.append(script);
        }
    }, [])

    const handleChangeInputs = event => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
      }

    const sendEmail = e => {
        e.preventDefault()
        window.location.href = `mailto:enmanuellopez02@hotmail.com?subject=New%20Email&body=Name%3A${inputs.name}%0AEmail%3A${inputs.email}%0AProject%3A${inputs.project}%0AMessage%3A${inputs.message}`
    }

    return (
    <>
      {/* <!--==================== HEADER ====================--> */}
        <header className="header" id="header">
            <nav className="nav container">
                <a href="#home" className="nav__logo">{ title }</a>
                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list grid">
                        <li className="nav__item">
                            <a href="#home" className="nav__link active-link">
                                <i className="uil uil-estate nav__icon"></i> Home
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#about" className="nav__link">
                                <i className="uil uil-user nav__icon"></i> About
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#skills" className="nav__link">
                                <i className="uil uil-file-alt nav__icon"></i> Skills
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#services" className="nav__link">
                                <i className="uil uil-briefcase-alt nav__icon"></i> Services
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#portfolio" className="nav__link">
                                <i className="uil uil-scenery nav__icon"></i> Portfolio
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="#contact" className="nav__link">
                                <i className="uil uil-message nav__icon"></i> Contactme
                            </a>
                        </li>
                    </ul>
                    <i className="uil uil-times nav__close" id="nav-close"></i>
                </div>
                <div className="nav__btns">
                    {/* <!-- Theme change button --> */}
                    <i className="uil uil-moon change-theme" id="theme-button"></i>
                    <div className="nav__toggle" id="nav-toggle">
                        <i className="uil uil-apps"></i>
                    </div>
                </div>
            </nav>
        </header>

        {/* <!--==================== MAIN ====================--> */}
        <main className="main">
            {/* <!--==================== HOME ====================--> */}
            <section className="home section" id="home">
                <div className="home__container container grid">
                     <div className="home__content grid">
                         <div className="home__social">
                             <a href="https://www.linkedin.com/in/luis-enmanuel-l%C3%B3pez-molina-091b02114" className="home__social-icon" target="_blank" rel="noreferrer">
                                <i className="uil uil-linkedin-alt"></i>
                             </a>
                             <a href="https://evospike.com" className="home__social-icon" target="_blank" rel="noreferrer">
                                <i className="uil uil-dribbble"></i>
                            </a>
                            <a href="https://github.com/enmanuellopez02" className="home__social-icon" target="_blank" rel="noreferrer">
                                <i className="uil uil-github-alt"></i>
                            </a>
                         </div>
                          <div className="home__img">
                            <svg className="home__blob" viewBox="0 0 200 187">
                                <mask id="mask0" mask-type="alpha">
                                    <path d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 165.547 
                                    130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 129.362C2.45775 
                                    97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 -0.149132 97.9666 
                                    0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"/>
                                </mask>
                                <g mask="url(#mask0)">
                                    <path d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 
                                    165.547 130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 
                                    129.362C2.45775 97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 
                                    -0.149132 97.9666 0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"/>
                                    <image className="home__blob-img" x="12" y="18" href={perfilImg.src}/>
                                </g>
                            </svg>
                          </div>
                          <div className="home__data">
                              <h1 className="home__title">Hi, I'm { title }</h1>
                              <h3 className="home__subtitle">{ description }</h3>
                              <p className="home__description">High level experience in web design and development, knowledge, producing quality work.</p>
                              <a href="#contact" className="button button--flex">
                                Contact Me <i className="uil uil-message button__icon"></i>
                              </a>
                          </div>
                     </div>
                     <div className="home__scroll">
                        <a href="#about" className="home__scroll-button button--flex">
                            <i className="uil uil-mouse-alt home__scroll-mouse"></i>
                            <span className="home__scroll-name">Scroll down</span>
                            <i className="uil uil-arrow-down home__scroll-arrow"></i>
                        </a>
                     </div>
                </div>
            </section>

            {/* <!--==================== ABOUT ====================--> */}
            <section className="about section" id="about">
                <h2 className="section__title">About Me</h2>
                <span className="section__subtitle">My experience</span>
                <div className="about__container container grid">
                    <Img fluid={aboutImg} className="about__img" />
                    <div className="about__data">
                        <p className="about__description">Web developer, with extensive knowledge and years of experiences, working in web technologies and UI / UX design , delivering quality work.</p>
                        <div className="about__info">
                            <div>
                                <span className="about__info-title">06+</span>
                                <span className="about__info-name">Years <br/> experience</span>
                            </div>
                            <div>
                                <span className="about__info-title">20+</span>
                                <span className="about__info-name">Completed <br/> project</span>
                            </div>
                            <div>
                                <span className="about__info-title">05+</span>
                                <span className="about__info-name">Companies <br/> worked</span>
                            </div>
                        </div>
                        <div className="about__buttons">
                            <a href="/Luis-Enmanuel-CV.pdf" download="" className="button button--flex">
                                Download CV<i className="uil uil-download-alt button__icon"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--==================== SKILLS ====================--> */}
            <section className="skills section" id="skills">
                <h2 className="section__title">Skills</h2>
                <span className="section__subtitle">My technical level</span>
                <div className="skills__container container grid">
                    <div>
                        {/* <!--==================== SKILLS 1 ====================--> */}
                        <div className="skills__content skills__open">
                            <div className="skills__header">
                                <i className="uil uil-brackets-curly skills__icon"></i>
                                <div>
                                    <h1 className="skills__title">Frontend developer</h1>
                                    <span className="skills__subtitle">More than 6 years</span>
                                </div>
                                <i className="uil uil-angle-down skills__arrow"></i>
                            </div>
                            <div className="skills__list grid">
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">HTML</h3>
                                        <span className="skills__number">95%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__html"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">CSS</h3>
                                        <span className="skills__number">97%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__css"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">Javascript</h3>
                                        <span className="skills__number">95%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__js"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">React</h3>
                                        <span className="skills__number">90%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__react"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">Vue</h3>
                                        <span className="skills__number">90%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__blazor"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">TypeScript</h3>
                                        <span className="skills__number">90%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__blazor"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!--==================== SKILLS 2 ====================--> */}
                        <div className="skills__content skills__close">
                            <div className="skills__header">
                                <i className="uil uil-server-network skills__icon"></i>
                                <div>
                                    <h1 className="skills__title">Backend developer</h1>
                                    <span className="skills__subtitle">More than 6 years</span>
                                </div>
                                <i className="uil uil-angle-down skills__arrow"></i>
                            </div>
                            <div className="skills__list grid">
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">Node.js</h3>
                                        <span className="skills__number">90%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__node"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">.Net Core</h3>
                                        <span className="skills__number">90%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__netcore"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">Firebase</h3>
                                        <span className="skills__number">85%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__firebase"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">Azure cloud</h3>
                                        <span className="skills__number">90%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__azure"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">Sql Server</h3>
                                        <span className="skills__number">80%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__sql"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">MongoDB</h3>
                                        <span className="skills__number">80%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__mongo"></span>
                                    </div>
                                </div>
                                <div className="skills__data">
                                    <div className="skills__titles">
                                        <h3 className="skills__name">Docker</h3>
                                        <span className="skills__number">80%</span>
                                    </div>
                                    <div className="skills__bar">
                                        <span className="skills__percentage skills__docker"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--==================== QUALIFICATION ====================--> */}
            <section className="qualification section">
                <h2 className="section__title">Qualification</h2>
                <span className="section__subtitle">My personal journey</span>
                <div className="qualification__container container">
                    <div className="qualification__tabs">
                        <div className="qualification__button button--flex qualification__active" data-target="#education">
                            <i className="uil uil-graduation-cap qualification__icon"></i> Education
                        </div>
                        <div className="qualification__button button--flex" data-target="#work">
                            <i className="uil uil-briefcase-alt qualification__icon"></i> Work
                        </div>
                    </div>
                    <div className="qualification__sections">
                        {/* <!--==================== QUALIFICATION CONTENT 1 ====================--> */}
                        <div className="qualification__content qualification__active" data-content id="education">
                            {/* <!--==================== QUALIFICATION 1 ====================--> */}
                            <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Computer Enginner</h3>
                                    <span className="qualification__subtitle">O&M - University</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>
                                        2013 - 2018
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                            </div>
                            {/* <!--==================== QUALIFICATION 2 ====================--> */}
                            <div className="qualification__data">
                                <div></div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                                <div>
                                    <h3 className="qualification__title">Web Developer and Azure Cloud Developer</h3>
                                    <span className="qualification__subtitle">Dominican Republic - Azure cloud developer certificate</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>
                                        2020 - 2021
                                    </div>
                                </div>
                            </div>
                            {/* <!--==================== QUALIFICATION 3 ====================--> */}
                            <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Microservices Arquitecture</h3>
                                    <span className="qualification__subtitle">Udemy - University</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>
                                        2020 - 2021
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    {/* <!-- <span className="qualification__line"></span> --> */}
                                </div>
                            </div>
                        </div>

                        {/* <!--==================== QUALIFICATION CONTENT 2 ====================--> */}
                        <div className="qualification__content" data-content id="work">
                            {/* <!--==================== QUALIFICATION 1 ====================--> */}
                            <div className="qualification__data">
                                <div>
                                    <h3 className="qualification__title">Software Developer Leader</h3>
                                    <span className="qualification__subtitle">iPlus Technology - Dominican Republic </span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>
                                        2016 - 2022
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                            </div>
                            {/* <!--==================== QUALIFICATION 2 ====================--> */}
                            <div className="qualification__data">
                                <div></div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    {/* <!-- <span className="qualification__line"></span> --> */}
                                </div>
                                <div>
                                    <h3 className="qualification__title">General Manager</h3>
                                    <span className="qualification__subtitle">EvoSpike - Dominican Republic</span>
                                    <div className="qualification__calendar">
                                        <i className="uil uil-calendar-alt"></i>
                                        2021 - 2022
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--==================== SERVICES ====================--> */}
            <section className="services section" id="services">
                <h2 className="section__title">Services</h2>
                <span className="section__subtitle">What i offer</span>
                <div className="services__container container grid">
                    {/* <!--==================== SERVICE 1 ====================--> */}
                    <div className="services__content">
                        <div>
                            <i className="uil uil-web-grid services__icon"></i>
                            <h3 className="services__title">UI/UX <br/> Designer</h3>
                        </div>
                        <span className="button button--flex button--small button--link services__button">
                            View More
                            <i className="uil uil-arrow-right button__icon"></i>
                        </span>
                        <div className="services__modal">
                            <div className="services__modal-content">
                                <h4 className="services__modal-title">UI/UX <br/> Designer</h4>
                                <i className="uil uil-times services__modal-close"></i>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>I develop the user interface.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Web page development.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>I create ux element interactions.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>I position your company brand.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!--==================== SERVICE 2 ====================--> */}
                    <div className="services__content">
                        <div>
                            <i className="uil uil-arrow services__icon"></i>
                            <h3 className="services__title">Frontend <br/> Developer</h3>
                        </div>
                        <span className="button button--flex button--small button--link services__button">
                            View More
                            <i className="uil uil-arrow-right button__icon"></i>
                        </span>
                        <div className="services__modal">
                            <div className="services__modal-content">
                                <h4 className="services__modal-title">Frontend <br/> Developer</h4>
                                <i className="uil uil-times services__modal-close"></i>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>I develop the user interface.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Web page development.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Optimize the performance of your website.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>I position the SEO of your brand.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!--==================== SERVICE 3 ====================--> */}
                    <div className="services__content">
                        <div>
                            <i className="uil uil-pen services__icon"></i>
                            <h3 className="services__title">Backend <br/> Developer</h3>
                        </div>
                        <span className="button button--flex button--small button--link services__button">
                            View More
                            <i className="uil uil-arrow-right button__icon"></i>
                        </span>
                        <div className="services__modal">
                            <div className="services__modal-content">
                                <h4 className="services__modal-title">Backend <br/> Developer</h4>
                                <i className="uil uil-times services__modal-close"></i>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>I develop the Backend interface.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Web page development.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Optimize your server performance.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Improved your cloud infrastructure.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!--==================== SERVICE 4 ====================--> */}
                    <div className="services__content">
                        <div>
                            <i className="uil uil-cloud services__icon"></i>
                            <h3 className="services__title">Azure <br/> Cloud</h3>
                        </div>
                        <span className="button button--flex button--small button--link services__button">
                            View More
                            <i className="uil uil-arrow-right button__icon"></i>
                        </span>
                        <div className="services__modal">
                            <div className="services__modal-content">
                                <h4 className="services__modal-title">Azure <br/> Cloud</h4>
                                <i className="uil uil-times services__modal-close"></i>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Continuous integration and Continuous deployment.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Manage your services.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Optimize your server performance.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Improved your cloud infrastructure.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <!--==================== SERVICE 5 ====================--> */}
                    <div className="services__content">
                        <div>
                            <i className="uil uil-cloud-question services__icon"></i>
                            <h3 className="services__title">Advisory <br/> Cloud</h3>
                        </div>
                        <span className="button button--flex button--small button--link services__button">
                            View More
                            <i className="uil uil-arrow-right button__icon"></i>
                        </span>
                        <div className="services__modal">
                            <div className="services__modal-content">
                                <h4 className="services__modal-title">Advisory <br/> Cloud</h4>
                                <i className="uil uil-times services__modal-close"></i>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Look for solutions that are more profitable.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Manage your services.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>More productivity and versatility.</p>
                                    </li>
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p>Reduce infrastructure and administration costs.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!--==================== PORTFOLIO ====================--> */}
            <section className="portfolio section" id="portfolio">
                <h2 className="section__title">Portfolio</h2>
                <span className="section__subtitle">Most recent work</span>
                <div className="portfolio__container container swiper">
                    <div className="swiper-wrapper">
                        {/* <!--==================== PORTFOLIO 1 ====================--> */}
                        <div className="portfolio__content grid swiper-slide">
                            <Img fluid={portfolio1Img} className="portfolio__img" />
                            <div className="portfolio__data">
                                <h3 className="portfolio__title">Modern Website</h3>
                                <p className="portfolio__description">Website adaptable to all devices, with ui components and animated interactions.</p>
                                <a href="https://evospike.com" target="_blank" rel="noreferrer" className="button button--flex button--small portfolio__button">
                                    Demo
                                    <i className="uil uil-arrow-right button__icon"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!--==================== PORTFOLIO 2 ====================--> */}
                        <div className="portfolio__content grid swiper-slide">
                            <Img fluid={portfolio2Img} className="portfolio__img" />
                            <div className="portfolio__data">
                                <h3 className="portfolio__title">Web development</h3>
                                <p className="portfolio__description">Tools EvoSpike gives you potential tools to automate your business.</p>
                                <a href="https://tools.evospike.com" target="_blank" rel="noreferrer" className="button button--flex button--small portfolio__button">
                                    Demo
                                    <i className="uil uil-arrow-right button__icon"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!--==================== PORTFOLIO 3 ====================--> */}
                        <div className="portfolio__content grid swiper-slide">
                            <Img fluid={portfolio3Img} className="portfolio__img" />
                            <div className="portfolio__data">
                                <h3 className="portfolio__title">Logistics Website - Canguru</h3>
                                <p className="portfolio__description">Logistics shipping system.</p>
                                <a href="https://canguru.io" target="_blank" rel="noreferrer"  className="button button--flex button--small portfolio__button">
                                    Demo
                                    <i className="uil uil-arrow-right button__icon"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!--==================== PORTFOLIO 4 ====================--> */}
                        <div className="portfolio__content grid swiper-slide">
                            <Img fluid={portfolio4Img} className="portfolio__img" />
                            <div className="portfolio__data">
                                <h3 className="portfolio__title">Multiple app services architecture</h3>
                                <p className="portfolio__description">Multi-service app architecture implementation.</p>
                                <a href={portfolio4Img.src} target="_blank" rel="noreferrer"  className="button button--flex button--small portfolio__button">
                                    Demo
                                    <i className="uil uil-arrow-right button__icon"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!--==================== PORTFOLIO 5 ====================--> */}
                        <div className="portfolio__content grid swiper-slide">
                            <Img fluid={portfolio5Img} className="portfolio__img" />
                            <div className="portfolio__data">
                                <h3 className="portfolio__title">Bot services</h3>
                                <p className="portfolio__description">Bot services multi-channel.</p>
                                <a href={portfolio5Img.src} target="_blank" rel="noreferrer"  className="button button--flex button--small portfolio__button">
                                    Demo
                                    <i className="uil uil-arrow-right button__icon"></i>
                                </a>
                            </div>
                        </div>
                        {/* <!--==================== PORTFOLIO 6 ====================--> */}
                        <div className="portfolio__content grid swiper-slide">
                            <Img fluid={portfolio6Img} className="portfolio__img" />
                            <div className="portfolio__data">
                                <h3 className="portfolio__title">EvoSpike Support</h3>
                                <p className="portfolio__description">Online support multi-languages.</p>
                                <a href="https://support.evospike.com" target="_blank" rel="noreferrer"  className="button button--flex button--small portfolio__button">
                                    Demo
                                    <i className="uil uil-arrow-right button__icon"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-button-next">
                        <i className="uil uil-angle-right-b swiper-portfolio-icon"></i>
                    </div>
                    <div className="swiper-button-prev">
                        <i className="uil uil-angle-left-b swiper-portfolio-icon"></i>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </section>

            {/* <!--==================== PROJECT IN MIND ====================--> */}
            <section className="project section">
                <div className="project__bg">
                    <div className="project__container container grid">
                        <div className="project__data">
                            <h2 className="project__title">You have a new project</h2>
                            <p className="project__description">Contact me now and get a 30% discount on your new project.</p>
                            <a href="#contact" className="button button--flex button--white">
                                Contact Me
                                <i className="uil uil-message project__icon button__icon"></i>
                            </a>
                        </div>
                        <Img fluid={projectImg} className="project__img" />
                    </div>
                </div>
            </section>

            {/* <!--==================== TESTIMONIAL ====================--> */}
            <section className="testimonial section">
                <h2 className="section__title">Testimonial</h2>
                <span className="section__subtitle">My client saying</span>
                <div className="testimonial__container container swiper">
                    <div className="swiper-wrapper">
                        {/* <!--==================== TESTIMONIAL 1 ====================--> */}
                        <div className="testimonial__content swiper-slide">
                            <div className="testimonial__data">
                                <div className="testimonial__header">
                                    <Img fluid={testimonial1Img} className="testimonial__img" />
                                    <div>
                                        <h3 className="testimonial__name">Sara Smith</h3>
                                        <span className="testimonial__client">Cliente</span>
                                    </div>
                                </div>
                                <div>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                </div>
                            </div>
                            <p className="testimonial__description">I get a good impression, I carry out my project with all the possible quality and attention and support 24 hours a day.</p>
                        </div>
                         {/* <!--==================== TESTIMONIAL 2 ====================--> */}
                         <div className="testimonial__content swiper-slide">
                            <div className="testimonial__data">
                                <div className="testimonial__header">
                                    <Img fluid={testimonial2Img} className="testimonial__img" />
                                    <div>
                                        <h3 className="testimonial__name">Matt Robinson</h3>
                                        <span className="testimonial__client">Cliente</span>
                                    </div>
                                </div>
                                <div>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                </div>
                            </div>
                            <p className="testimonial__description">Enmanuel is not only a supplier, he is a strategic partner of our company that supports us in achieving our objectives through recommendations and implementation of technological solutions.</p>
                        </div>
                         {/* <!--==================== TESTIMONIAL 3 ====================--> */}
                         <div className="testimonial__content swiper-slide">
                            <div className="testimonial__data">
                                <div className="testimonial__header">
                                    <Img fluid={testimonial3Img} className="testimonial__img" />
                                    <div>
                                        <h3 className="testimonial__name">Raul Harris</h3>
                                        <span className="testimonial__client">Cliente</span>
                                    </div>
                                </div>
                                <div>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                    <i className="uil uil-star testimonial__icon-start"></i>
                                </div>
                            </div>
                            <p className="testimonial__description">Enmanuel is for us a provider that provides agile and flexible solutions that support us with the constant changes in the market today. Maintaining the responsibility and guarantee in each of the deliverables that work.</p>
                        </div>
                    </div>
                    <div className="swiper-pagination swiper-pagination-testimonial"></div>
                </div>
            </section>

            {/* <!--==================== CONTACT ME ====================--> */}
            <section className="contact section" id="contact">
                <h2 className="section__title">Contact Me</h2>
                <span className="section__subtitle">Get in touch</span>
                <div className="contact__container container grid">
                    <div>
                        <div className="contact__information">
                            <i className="uil uil-phone contact__icon"></i>
                            <div>
                                <h3 className="contact__title">Call Me</h3>
                                <span className="contact__subtitle">829-757-7718</span>
                            </div>
                        </div>
                        <div className="contact__information">
                            <i className="uil uil-envelope contact__icon"></i>
                            <div>
                                <h3 className="contact__title">Email</h3>
                                <span className="contact__subtitle">{ contact }</span>
                            </div>
                        </div>
                        <div className="contact__information">
                            <i className="uil uil-map-marker contact__icon"></i>
                            <div>
                                <h3 className="contact__title">Location</h3>
                                <span className="contact__subtitle">Dominican Republic - Santo Domingo Oeste</span>
                            </div>
                        </div>
                    </div>
                    <form className="contact__form grid" onSubmit={sendEmail}>
                        <div className="contact__inputs grid">
                            <div className="contact__content">
                                <label htmlFor="tb_name" className="contact__label">Name</label>
                                <input type="text" id="tb_name" className="contact__input" name="name" onChange={handleChangeInputs} required />
                            </div>
                            <div className="contact__content">
                                <label htmlFor="email" className="contact__label">Email</label>
                                <input type="email" id="email" className="contact__input" name="email" onChange={handleChangeInputs} required />
                            </div>
                        </div>
                        <div className="contact__content">
                            <label htmlFor="tb_project" className="contact__label">Project</label>
                            <input type="text" id="tb_project" className="contact__input" name="project" onChange={handleChangeInputs} required />
                        </div>
                        <div className="contact__content">
                            <label htmlFor="tb_message" className="contact__label">Message</label>
                            <textarea type="text" id="tb_message" className="contact__input" cols="0" rows="7" name="message" onChange={handleChangeInputs} required></textarea>
                        </div>
                        <div>
                            <button className="button button--flex">
                                Send Message
                                <i className="uil uil-message button__icon"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </main>

        {/* <!--==================== FOOTER ====================--> */}
        <footer className="footer">
            <div className="footer__bg">
                <div className="footer__container container grid">
                    <div>
                        <h1 className="footer__title">Enmanuel</h1>
                        <span className="footer__subtitle">Full stack developer and Azure cloud developer</span>
                    </div>
                    <ul className="footer__links">
                        <li>
                            <a href="#services" className="footer__link">Services</a>
                        </li>
                        <li>
                            <a href="#portfolio" className="footer__link">Portfolio</a>
                        </li>
                        <li>
                            <a href="#contact" className="footer__link">Contactme</a>
                        </li>
                    </ul>
                    <div className="footer__socials">
                        <a href="https://web.facebook.com/enmanuellopez02" target="_blank" rel="noreferrer" className="footer__social">
                            <i className="uil uil-facebook-f"></i>
                        </a>
                        <a href="https://www.instagram.com/enmanuellopez02/" target="_blank" rel="noreferrer" className="footer__social">
                            <i className="uil uil-instagram"></i>
                        </a>
                        <a href="https://twitter.com/enmanuellopez02" target="_blank" rel="noreferrer" className="footer__social">
                            <i className="uil uil-twitter-alt"></i>
                        </a>
                    </div>
                </div>
                <p className="footer__copy">&#169; { copyright }</p>
            </div>
        </footer>
        {/* <!--==================== SCROLL TOP ====================--> */}
        <a href="#home" className="scrollup" id="scroll-up">
            <i className="uil uil-arrow-up scrollup__icon"></i>
        </a>
    </>
  )
}

export const query = graphql`
    query SiteInfo {
        allFile {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title,
                description,
                copyright,
                contact
            }
        }
    }
`